<template>
  <div>
    <!-- si no hay categoria seleccionada -->
    <div v-if="items == [] || items == undefined">
      <div class="py-5 px-2 text-center">
        <div>
          <i
            style="font-size: 5rem; color: var(--muted-progress)"
            class="fa-solid fa-list mb-2"
          ></i>
        </div>
        <h3>¡Selecciona una categoria!</h3>
        <p>No tienes ninguna categoria seleccionada.</p>
      </div>
    </div>

    <!-- si hay categoria seleccionada -->
    <b-collapse
      v-else
      tag="ul"
      :class="className + ' text-dark'"
      :visible="open"
      :id="idName"
      :accordion="accordianName"
      style="word-wrap: break-word !important"
      class="mx-3"
    >
      <div v-for="itemData of items.subGrupo" class="mb-2" :key="itemData.id">
        <b-button
          v-if="itemData.nivel && itemData.nivel.includes(usuario.rol?.nivel)"
          :class="
            'd-flex align-items-center ' + (visible ? null : 'collapsed' + '')
          "
          :aria-expanded="visible ? 'true' : 'false'"
          aria-controls="collapse-4"
          style="
            text-align: left !important;
            font-size: 0.8rem !important;
            padding-right: 0;
            gap: 0.1rem;
          "
          block
          :variant="
            items.grupoSeleccionado == itemData.id ? 'primary' : 'light'
          "
          @click="itemVisible = itemData.id"
        >
          <i
            :class="
              itemVisible == itemData.id
                ? 'fas fa-caret-down'
                : 'fas fa-caret-right'
            "
            style="font-size: 0.6rem"
          ></i>
          <span class="text-truncate text-white pr-2">{{
            itemData.titulo
          }}</span>
        </b-button>
        <b-collapse
          v-for="itemView of itemData.items"
          :key="itemView.id"
          :id="'collapse-' + itemView.id"
          :visible="itemVisible === itemData.id"
        >
          <div
            v-if="itemView.nivel && itemView.nivel.includes(usuario.rol?.nivel)"
            class="ml-2 pointer mt-2"
            @click="abrirVista(itemView, itemData.id)"
          >
            <router-link :to="itemView.ruta" class="pointer">
              <template v-if="itemVisible == itemData.id">
                <p
                  :class="
                    'p-2 m-0 rounded hover-menu-items apps ' +
                    (items.grupoSeleccionado == itemData.id &&
                    items.vistaSeleccionada == itemView.id
                      ? 'bg-active-item active-menu-item'
                      : 'bg-dark')
                  "
                >
                  <span
                    style="
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      width: 97%;
                      display: block;
                    "
                  >
                    {{ itemView.titulo }}
                  </span>
                </p>
              </template>
            </router-link>
          </div>
        </b-collapse>
      </div>
    </b-collapse>
  </div>
</template>
<script>
// import List from './CollapseMenu'
import { core } from "@/config/pluginInit";
export default {
  name: "List",
  props: {
    items: {
      type: Object,
      default: () => ({ subGrupo: [] }),
    },
    className: { type: String, default: "iq-menu" },
    open: { type: Boolean, default: false },
    idName: { type: String, default: "sidebar" },
    accordianName: { type: String, default: "sidebar" },
    sidebarGroupTitle: { type: Boolean, default: true },
  },
  watch: {
    // cada vez que la pregunta cambie, esta función será ejecutada
    items: function () {
      // this.answer = 'Esperando que deje de escribir...'
      // this.debouncedGetAnswer()
      // alert('actualizado')

      // sincronizar ruta con el menu
      this.syncRouteWithMenu();

      if (this.items.grupoSeleccionado) {
        this.itemVisible = this.items.grupoSeleccionado;
      } else {
        this.itemVisible = 1;
      }
      console.log("item visible en mounted", this.itemVisible);
      this.$forceUpdate();
    },
  },
  components: {
    // List
  },
  computed: {
    activarPrimerElemento() {
      if (this.items.length > 0) {
        return true;
      } else {
        return true;
      }
    },
    // activarMensaje() {
    //   if (this.items.length > 0){
    //   console.log('aqui esta activarmensaje------------------->', this.items )
    //     return true
    //   }
    //   else{
    //     return false
    //   }

    // },
    hideListMenuTitle() {
      return this.sidebarGroupTitle;
    },
  },
  data() {
    return {
      itemVisible: 0,
      visible: true,
      usuario: {},
    };
  },
  mounted() {
    this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
    console.log("mountedcollapseblemenu", this.usuario.rol?.nivel);
  },
  methods: {
    syncRouteWithMenu() {
      if (!this.items || !this.items.subGrupo) {
        console.log("items o subGrupo no están definidos todavía.");
        return;
      }
      const currentRoute = this.$route.path;
      console.log("currentRoute", currentRoute);

      // Recorre los subGrupos para encontrar coincidencias con la ruta actual
      for (let subGrupo of this.items.subGrupo) {
        for (let itemView of subGrupo.items) {
          console.log("itemView", itemView);

          if (currentRoute.toLowerCase() === "/dashboard") {
            this.items.grupoSeleccionado = null;
            this.items.vistaSeleccionada = null;
            this.itemVisible = null;
            this.$forceUpdate();
            return;
          }

          if (itemView.ruta.toLowerCase() === currentRoute.toLowerCase()) {
            console.log(subGrupo);

            this.items.grupoSeleccionado = subGrupo?.id;
            this.items.vistaSeleccionada = itemView?.id;
            this.itemVisible = subGrupo?.id;
            this.$forceUpdate();
            return;
          }
        }
      }
    },
    imprimirConsola() {
      console.log("Imprimir en consola ------>", this.items);
    },
    abrirVista(itSel, subGrupoId) {
      this.items.grupoSeleccionado = subGrupoId;
      this.items.vistaSeleccionada = itSel.id;
      localStorage.setItem("menuseleccionado", JSON.stringify(this.items));
      this.itemVisible = subGrupoId;
      this.$forceUpdate();
    },
    activeLink(item) {
      return core.getActiveLink(item, this.$route.name);
    },
  },
};
</script>
