<template>
  <div class="px-2">
    <div class="row pt-0 mt-0 mx-0">
      <div class="col-6 col-md-4 px-0 mx-0" v-for="itemGroup of dataGroupV2" :key="itemGroup.groupId" @click="seleccionarCategoria(itemGroup)">
        <div
          :class="[
            'rounded p-2 m-1 hover-menu-items pointer ',
            categoriaSeleccionada && categoriaSeleccionada.groupId === itemGroup.groupId ? 'bg-active-item active-menu-item' : 'bg-dark',
          ]"
          style="height: 6.5rem"
          data-aos-easing="ease-in-sine"
          :data-aos-duration="900"
          :data-aos-offset="300"
        >
          <div class="text-center">
            <img class="text-center m-0 filter p-2" width="50px" :src="itemGroup.icono" alt="icon_categoria" />
          </div>
          <p class="text-center h6" style="word-wrap: normal">
            {{ itemGroup.titulo }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "../../main";

export default {
  props: ["dataGroupV2"],
  data() {
    return {
      itemGroupGen: [],
      group: null,
      recibido: null,
      categoriaSeleccionada: null,
    };
  },
  mounted() {
    this.setInitialCategory();
  },
  watch: {
    "$route.path": {
      handler(newPath) {
        this.handleUrlChange(newPath);
      },
      immediate: true,
    },
  },
  methods: {
    seleccionarCategoria(item) {
      console.log("categoria--->", item);
      this.categoriaSeleccionada = item;
      localStorage.setItem("menuseleccionado", JSON.stringify(item));
      // eventBus.$emit("colapsando", false);
      const width = window.innerWidth;
      console.log("ancho de pantalla", width);
      if (width < 1300) {
        eventBus.$emit("colapsandov3", false);
      } else {
        eventBus.$emit("colapsandov3", true);
      }

      eventBus.$emit("seleccionarcategoria", item);
    },
    setInitialCategory() {
      const url = this.$route.path;
      const categoriaGuardada = localStorage.getItem("menuseleccionado");

      if (categoriaGuardada) {
        this.categoriaSeleccionada = JSON.parse(categoriaGuardada);
      } else {
        this.categoriaSeleccionada = this.findCategoryByUrl(this.dataGroupV2, url);
        if (this.categoriaSeleccionada) {
          localStorage.setItem("menuseleccionado", JSON.stringify(this.categoriaSeleccionada));
        }
      }
    },
    handleUrlChange(url) {
      this.categoriaSeleccionada = this.findCategoryByUrl(this.dataGroupV2, url);

      if (url.toLowerCase() === "/dashboard") {
        localStorage.removeItem("menuseleccionado");
      }

      if (this.categoriaSeleccionada) {
        localStorage.setItem("menuseleccionado", JSON.stringify(this.categoriaSeleccionada));
      } else {
        console.log("No se encontró la categoría para la URL:", url);
      }
    },
    findCategoryByUrl(data, url) {
      function findGroupByRoute(obj, route) {
        // console.log("findGroupByRoute", obj, route);

        if (obj.subGrupo) {
          for (let group of obj.subGrupo) {
            if (findObjectByRoute(group, route)) {
              return obj;
            }
          }
        }
        if (obj.items) {
          for (let item of obj.items) {
            if (findObjectByRoute(item, route)) {
              return obj;
            }
          }
        }
        return null;
      }

      function findObjectByRoute(obj, route) {
        if (obj.ruta.toLowerCase() === route.toLowerCase()) {
          return obj;
        }
        if (obj.subGrupo) {
          for (let group of obj.subGrupo) {
            let result = findObjectByRoute(group, route);
            if (result) return result;
          }
        }
        if (obj.items) {
          for (let item of obj.items) {
            let result = findObjectByRoute(item, route);
            if (result) return result;
          }
        }
        return null;
      }

      for (let group of data) {
        let result = findGroupByRoute(group, url);
        if (result) return result;
      }
      return null;
    },
  },
};
</script>
