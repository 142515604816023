<template>
  <div>
    <!-- mapa conductual modal -->
    <b-modal
      id="modalMapaConductual"
      title="Mapa conductual"
      size="xl"
      hide-footer
      centered
    >
      <b-card class="overflow-hidden position-relative" bg-variant="dark">
        <!-- custom-circle -->
        <div class="position-absolute rounded-circle custom-circle"></div>
        <div class="row">
          <div
            class="col-12 col-lg-3 d-flex align-items-center justify-content-center"
          >
            <img
              style="width: 60%"
              src="/icons-mapaConductual/logo-arquitectura_conductual.png"
              alt=""
            />
          </div>
          <div class="col mt-3 mt-md-0">
            <span class="font-weight-bold h3 text-capitalize"
              >arquitectura conductual
            </span>
            <p class="text-white-50 m-0 h4">
              La diferencia está en las personas, cada uno es diferente, por
              ello es importante conocerlos mejor, para liderar mejor, conocer
              las personalidades de cada colaborador ayudará a mejorar la
              felicidad y la productividad empresarial.
            </p>
            <div class="d-flex align-items-center flex-wrap">
              <b-form-rating
                id="rating-inline"
                variant="warning"
                readonly
                inline
                show-value
                no-border
                value="4.5"
                class="bg-transparent p-0"
                size="xl"
              ></b-form-rating>
              <b-badge variant="success"> Promedio </b-badge>
            </div>
            <div class="d-flex align-items-center flex-wrap">
              <i class="fas fa-circle-check mr-1"></i>
              <span class="custom-punto d-flex align-items-center">
                última aplicación
                <span class="ml-1 text-primary"> Nov 24, 2023</span>
              </span>
              <span class="font-weight-bold"> 100+</span>
            </div>
          </div>
        </div>
      </b-card>
      <!-- listado de mapas -->
      <div class="row list-mapas justify-content-center mt-5 px-2">
        <div
          v-for="(mapa, index) in listMapasConductuales"
          :key="mapa.id"
          class="col-12 col-lg-5 d-flex align-items-center py-2 bg-dark rounded"
          :style="{
            transition: 'box-shadow 0.3s ease',
            boxShadow:
              activeCardMapaConductual == index
                ? 'inset 0 0 0 2px #186bdd'
                : 'none',
            cursor: 'default',
          }"
          @click="
            activeCardMapaConductual =
              activeCardMapaConductual === index ? null : index
          "
        >
          <!-- acciones  -->
          <b-dropdown
            class="position-absolute"
            style="top: 0; right: 0"
            size="sm"
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template #button-content>
              <i class="fas fa-ellipsis" style="font-size: 1.5rem"></i>
            </template>

            <b-dropdown-item-button
              ><i class="fas fa-edit"></i> Editar</b-dropdown-item-button
            >
            <b-dropdown-item-button
              ><i class="fas fa-trash text-danger"></i>
              <span class="text-danger ml-1"
                >Eliminar</span
              ></b-dropdown-item-button
            >
          </b-dropdown>
          <div class="row mt-2">
            <!-- icon mapas -->
            <div
              class="col-12 col-xl my-5 my-xl-0 d-flex justify-content-center align-items-center position-relative"
            >
              <div
                class="custom-bg-icon p-5 position-absolute"
                style="border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%"
              ></div>

              <img
                class="position-absolute"
                style="width: 80px"
                :src="mapa.icon"
                :alt="mapa.title"
              />
            </div>
            <!-- content mapas -->
            <div class="col-12 col-xl-9 my-2">
              <span class="font-weight-bold h4 text-capitalize">{{
                mapa.title
              }}</span>
              <p class="text-white-50 m-0">
                {{ mapa.description }}
              </p>
              <div class="d-flex align-items-center flex-wrap">
                <b-form-rating
                  id="rating-inline"
                  variant="warning"
                  readonly
                  inline
                  show-value
                  no-border
                  :value="mapa.promedio"
                  class="bg-transparent p-0"
                  size="sm"
                ></b-form-rating>
              </div>
              <div class="d-flex align-items-center flex-wrap">
                <i class="fas fa-circle-check mr-1"></i>
                <small class="custom-punto d-flex align-items-center">
                  última aplicación
                  <span class="ml-1 text-primary"> Nov 24, 2023</span>
                </small>
                <small class="font-weight-bold"> {{ index + 1 }}0+</small>
              </div>
            </div>
          </div>
        </div>
        <!-- adn conductual -->
        <vs-button
          class="col-12 col-lg-5 d-flex align-items-center"
          v-b-modal="'modal_report_adn'"
          :active="active == 1"
          @click="active = 1"
        >
          <div class="row">
            <!-- icon adn -->
            <div
              class="col-12 col-xl my-2 my-xl-0 d-flex justify-content-center align-items-center"
            >
              <img
                class="icon-adn"
                src="/icons-mapaConductual/adn.png"
                alt="adn-conductual"
              />
            </div>
            <!-- content mapas -->
            <div class="col-12 col-xl-8 my-2 d-flex flex-column">
              <span class="font-weight-bold h4 text-uppercase"
                >ADN conductual</span
              >
              <p class="text-white m-0 px-2">
                La genética de la conducta es el estudio de los factores
                genéticos y ambientales que originan las diferencias entre
                individuos.
              </p>
            </div>
          </div>
          <template #animate>
            <div class="row">
              <!-- content mapas -->
              <div class="col-12 col-xl-8 my-2 d-flex flex-column">
                <span class="font-weight-bold h4 text-white text-uppercase"
                  >¿Cómo influye el ADN en la personalidad?</span
                >
                <p class="text-white m-0 px-3">
                  Los científicos estiman que del 20 al 60 por ciento del
                  temperamento está determinado por la genética.
                </p>
              </div>
              <!-- icon adn -->
              <div
                class="col-12 col-xl my-2 my-xl-0 mr-xl-3 d-flex justify-content-center align-items-center"
              >
                <img
                  class="icon-adn"
                  src="/icons-mapaConductual/dna.png"
                  alt="adn-conductual"
                />
              </div>
            </div>
          </template>
        </vs-button>
      </div>
    </b-modal>

    <!-- modal adn powerBIreport -->
    <b-modal
      size="lg"
      title="Reporte de ADN"
      id="modal_report_adn"
      hide-footer
      centered
    >
      <b-card class="m-0">
        <!-- info report card -->
        <div class="row bg-dark rounded px-1 px-lg-2 py-3 mb-3">
          <div
            class="col-12 col-xl d-flex justify-content-center align-items-center mb-2"
          >
            <div
              class="rounded-circle text-white d-flex justify-content-center align-items-center"
              style="
                width: 80px;
                height: 80px;
                background: radial-gradient(circle, #ff94a7, #d70027);
                box-shadow: 0px -1px 33px -8px #d70027;
                -webkit-box-shadow: 0px -1px 33px -8px #d70027;
                -moz-box-shadow: 0px -1px 33px -8px #d70027;
              "
            >
              <i class="fas fa-chart-simple" style="font-size: 2rem"></i>
            </div>
          </div>
          <div class="col-12 col-xl-10">
            <h3 class="font-weight-bold text-uppercase">
              Reporte ADN Conductual
            </h3>
            <div class="d-flex align-items-center flex-wrap">
              <span class="custom-punto d-flex align-items-center">
                <i class="fas fa-circle-check mr-1"></i> última actualización
                <span class="text-primary ml-1"> Nov 24, 2023</span>
              </span>
              <b-badge variant="success">Nivel 1</b-badge>
            </div>
          </div>
        </div>

        <!-- content iframe powerBI report -->
        <div
          class="row d-flex justify-content-center align-items-center"
          style="gap: 1rem"
        >
          <div
            class="col-12 col-xl-7 d-flex justify-content-center align-items-center"
            style="height: 300px"
          >
            <h5>Cargando reporte...</h5>
            <modalIframe
              :url="dataPowerBI.url"
              :likeElement="true"
            ></modalIframe>
          </div>
          <div class="col-12 col-xl">
            <div class="row" style="gap: 1rem; height: 300px">
              <div
                class="col-12 d-flex flex-column align-items-center justify-content-around bg-dark rounded py-2"
              >
                <div class="d-flex w-100" style="gap: 0.5rem">
                  <i class="fas fa-key p-2 rounded-circle border"></i>
                  <div class="d-flex align-items-center flex-wrap">
                    <small
                      class="text-capitalize custom-punto d-flex align-items-center"
                      >contraseña reporte</small
                    >
                    <small class="text-primary ml-1"> Nov 24, 2023</small>
                  </div>
                </div>
                <div class="w-100">
                  <input
                    readonly
                    ref="passwordInput"
                    :type="`${showPassword ? 'text' : 'password'}`"
                    :style="showPassword ? 'fontSize:1.5rem' : ''"
                    class="custom-input-password"
                    value="reporte12345"
                  />
                  <div class="d-flex">
                    <vs-button
                      class="w-100"
                      danger
                      :active="active == 3"
                      @click="
                        copyToClipboard(
                          'top-right',
                          'primary',
                          `<i class='fas fa-copy'></i>`
                        )
                      "
                    >
                      <i
                        :class="`fas fa-${
                          copyPasswordText ? 'circle-check' : 'copy'
                        } mr-1`"
                      ></i>
                      {{ copyPasswordText ? "Copiada!" : "Copiar" }}
                    </vs-button>
                    <vs-button
                      class="w-100"
                      primary
                      :active="active == 3"
                      @click="togglePasswordVisibility"
                    >
                      <i
                        :class="`fas fa-${
                          showPassword ? 'eye-slash' : 'eye'
                        } mr-1`"
                      ></i>
                      {{ showPassword ? "Ocultar" : "Ver" }}
                    </vs-button>
                  </div>
                </div>
              </div>
              <div
                class="col-12 bg-dark rounded py-2 d-flex align-items-center"
              >
                <div class="d-flex align-items-center">
                  <div
                    class="rounded-circle text-white d-flex justify-content-center align-items-center mr-2"
                    style="
                      width: 50px;
                      height: 50px;
                      background: linear-gradient(to right, #ffea46, #ffba00);
                      box-shadow: 0px -1px 33px -8px #ffba00;
                      -webkit-box-shadow: 0px -1px 33px -8px #ffba00;
                      -moz-box-shadow: 0px -1px 33px -8px #ffba00;
                    "
                  >
                    <i class="fas fa-brain" style="font-size: 1.5rem"></i>
                  </div>
                  <div>
                    <h4>Mapeo Conductual</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-card>
    </b-modal>
  </div>
</template>

<script>
import modalIframe from "@/components/helexium/modalIframe.vue";

export default {
  components: {
    modalIframe,
  },
  data() {
    return {
      active: null,
      copyPasswordText: null,
      showPassword: false,
      activeCardMapaConductual: null,
      dataPowerBI: {
        url: "https://app.powerbi.com/view?r=eyJrIjoiM2NjZmVkODQtMDFkYi00ZmIzLTkwMGQtNjBjOGFkN2JiZTkzIiwidCI6IjU3ZTM1Nzc2LWMzZDMtNGQxMS1iNGJkLTMzYTZlMzk5MTNlMSJ9",
      },
      listMapasConductuales: [
        {
          id: 1,
          title: "MAPEO DE PERSONALIDAD - CARÁCTER",
          description:
            "Aplique este instrumento para identificar que Tipo de personalidad tiene un colaborador. Esto se puede realizar a una persona, un grupo o toda la organización.",
          promedio: 3.3,
          icon: "/icons-mapaConductual/personalidad.png",
        },
        {
          id: 2,
          title: "MAPEO MOTIVACIÓN",
          description:
            "Identifica el tipo de motivación Intrínseca o  Extrínseca de los colaboradores de la compañía, no hacerlo es creer que todos somos iguales.",
          promedio: 2.5,
          icon: "/icons-mapaConductual/motivacion.png",
        },
        {
          id: 3,
          title: "MAPEO DE CAPACIDAD - DESEMPEÑO",
          description:
            "Podrá identificar si el colaborador tiene la oportunidad, la capacidad y los recursos para poder gestionar el cambio de conducta.",
          promedio: 4.2,
          icon: "/icons-mapaConductual/capacidad.png",
        },
        {
          id: 4,
          title: "MAPEO DE CAPITAL PSICOLOGICO Y ENTORNO",
          description:
            "Es necesario establecer los recursos internos que se necesitan para gestionar el cambio, como lo son la confianza en uno mismo, la fuerza de voluntad, el optimismo y la resiliencia, realice el mapeo de estos elementos.",
          promedio: 4.5,
          icon: "/icons-mapaConductual/psicologo.png",
        },
        {
          id: 5,
          title: "GIMNASIO DE HÁBITOS",
          description:
            "Establezca de acuerdo con los resultados de desempeño, que rutas se deben indicar por cada colaborador , haga seguimiento mutuo y triunfen como organización.",
          promedio: 4.8,
          icon: "/icons-mapaConductual/cerebro.png",
        },
        {
          id: 6,
          title: "GAMIFICA Y RECONOCE",
          description:
            "Las palabras y hasta los número son buenos para motivar, pero las imágenes son fundamentales para crear reforzamiento, y siempre podemos reconocer a quien lo quiere lograr o lo ha logrado, envía medallas a quien este en ese camino.",
          promedio: 4.7,
          icon: "/icons-mapaConductual/gamificacion.png",
        },
        {
          id: 7,
          title: "MAPEO DE ARQUITECTURA DEL ENTORNO",
          description:
            "Tomemos el siguiente ejemplo: Imagínate que un miembro de tu familia sufre una enfermedad mental, como depresión o esquizofrenia. ¿Cómo sería? ¿ Cómo te sentirías?. Ahora imagínate cómo te sentirías si un médico te dijera que tú eres la causad de esa enfermedad. Esto es lo que se identifica con este tipo de mapeo.",
          promedio: 4.1,
          icon: "/icons-mapaConductual/gestion_arquitectura.png",
        },
      ],
    };
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    async copyToClipboard(position = null, color, icon) {
      try {
        const inputPassword = this.$refs.passwordInput.value;
        if (inputPassword) {
          await navigator.clipboard.writeText(inputPassword);

          this.copyPasswordText = inputPassword;

          setTimeout(() => {
            this.copyPasswordText = null;
          }, 6000);

          this.$vs.notification({
            // duration: "none",
            progress: "auto",
            icon,
            color,
            position,
            title: "Contraseña copiada!!",
          });
        }
      } catch (err) {
        this.$vs.notification({
          // duration: "none",
          progress: "auto",
          icon: '<i class="fas fa-xmark"></i>',
          color: "danger",
          position,
          title: "No se pudo copiar al portapapeles!!",
          text: err,
        });
        console.error("No se pudo copiar al portapapeles!!: ", err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/* custom input password */
.custom-input-password {
  background: transparent;
  border: none;
  width: 100%;
  height: 50px;
  color: var(--muted-progress);
  font-size: 3rem;
  cursor: default;
  transition: all 0.3s ease;
  animation: fadeIn 3s infinite;
}

@keyframes fadeIn {
  0%,
  100% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
}

/* content list mapas */
.list-mapas {
  max-height: 500px;
  overflow-y: scroll;
  gap: 1rem;
}

/* icon adn */
.icon-adn {
  width: 100px;
  transform: rotate(10deg);
  animation: leftAndRight 2s ease-in-out infinite;
}
@keyframes leftAndRight {
  0%,
  100% {
    transform: rotate(10deg) translateY(0);
  }
  50% {
    transform: rotate(10deg) translateY(-10px);
  }
}

@media (min-width: 992px) {
  .custom-circle {
    width: 310px;
    top: -40px;
    left: -50px;
  }
}
</style>
