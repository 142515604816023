<template>
  <div class="modalDialog contenedor-modal" v-if="modalShowLocal == true">
    <div class="text-white responsive-xl-md" data-aos="zoom-in">
      <!-- <a title="Close" class="close_x text-white contenedor-modal zoom" @click="modalShowLocal=false" -->
      <a
        title="Close"
        class="close_x text-white contenedor-modal zoom"
        @click="$store.commit('modalShowRefresh', false)"
      >
        <span style="margin: auto"><b> x </b></span></a
      >
      <div class="row contenedor-modal">
        <div class="col-xl-12" data-aos="zoom-in" data-aos-duration="900">
          <div class="row">
            <!--  -->
            <template v-for="itemGroup of dataGroup">
              <div
                v-if="itemGroup.nivel.includes(usuario.rol.nivel)"
                :key="itemGroup.id"
                :class="usuario.rol.nivel == 5 ? 'col' : 'col-3'"
                data-aos-easing="ease-in-sine"
                :data-aos-duration="900"
                :data-aos-offset="300"
              >
                <!-- <div v-if="itemGroup.id == 13" class="text-center ml-3 mr-3 mb-2 mt-2">
                  <a href="https://helexium-lego-sistema-de-gestion.vercel.app/" target="_blank">
                    <img class="text-center p-1 m-0 zoom filter" style="width: 55px; border: 2px solid gray; padding: 10px; border-radius: 10px" :src="itemGroup.icon" alt="" />
                  </a>
                </div> -->

                <div
                  v-if="itemGroup.id == 14"
                  class="text-center ml-3 mr-3 mb-2 mt-2"
                  @click="
                    $bvModal.show('modalMapaConductual');
                    $store.commit('groupRefresh', itemGroup.id);
                  "
                >
                  <img
                    class="text-center p-1 m-0 zoom filter"
                    style="
                      width: 55px;
                      border: 2px solid gray;
                      padding: 10px;
                      border-radius: 10px;
                    "
                    :src="itemGroup.icon"
                    alt=""
                  />
                </div>

                <div
                  v-else-if="itemGroup.id != 14 && itemGroup.id != 21"
                  class="text-center m-2"
                  @click="
                    $store.commit('groupRefresh', itemGroup.id);
                    $store.commit('modalShowRefresh', false);
                    $router.push(itemGroup.path);
                  "
                >
                  <img
                    class="text-center p-1 m-0 zoom filter"
                    style="
                      width: 55px;
                      border: 2px solid gray;
                      padding: 10px;
                      border-radius: 10px;
                    "
                    :src="itemGroup.icon"
                    alt=""
                  />
                  <!-- <b-badge v-if="[3, 8, 14].includes(itemGroup.id) && getPendingCount(itemGroup.id) > 0" class="position-absolute">{{ getPendingCount(itemGroup.id) }}</b-badge> -->
                  <!-- {{ itemGroup.id }}
                  {{ [7, 9, 20].includes(itemGroup.id) }}
                  {{ "/" + getPendingCount(itemGroup.id) }} -->
                  <b-badge
                    v-if="
                      [7, 9, 19].includes(itemGroup.id) &&
                      getPendingCount(itemGroup.id) > 0
                    "
                    class="position-absolute"
                    >{{ getPendingCount(itemGroup.id) }}</b-badge
                  >
                </div>

                <p v-if="itemGroup.id != 21" class="text-center">
                  {{ itemGroup.group }}
                </p>
              </div>
            </template>
            <modalMapaConductual_component />
          </div>
        </div>
      </div>
    </div>

    <div class="text-white responsive-sm" data-aos="zoom-in">
      <a
        title="Close"
        class="close_x text-white contenedor-modal zoom"
        @click="$store.commit('modalShowRefresh', false)"
      >
        <span style="margin: auto"><b> x </b></span></a
      >
      <div class="row contenedor-modal p-4">
        <div class="col-xl-12" data-aos="zoom-in" data-aos-duration="900">
          <div class="row">
            <template v-for="itemGroup of dataGroup">
              <div
                v-if="itemGroup.nivel.includes(usuario.rol.nivel)"
                :key="itemGroup.id"
                :class="usuario.rol.nivel == 5 ? 'col' : 'col-4'"
                data-aos-easing="ease-in-sine"
                :data-aos-duration="900"
                :data-aos-offset="300"
              >
                <div v-if="itemGroup.id == 19" class="text-center m-3">
                  <a
                    href="https://helexium-lego-sistema-de-gestion.vercel.app/"
                    target="_blank"
                  >
                    <img
                      class="text-center p-1 m-0 zoom filter"
                      style="
                        width: 40px;
                        border: 2px solid gray;
                        padding: 10px;
                        border-radius: 10px;
                      "
                      :src="itemGroup.icon"
                      alt=""
                    />
                  </a>
                </div>
                <div
                  v-else-if="itemGroup.id == 21"
                  class="text-center m-3"
                  @click="
                    $bvModal.show('modalMapaConductual');
                    $store.commit('groupRefresh', itemGroup.id);
                  "
                >
                  <img
                    class="text-center p-1 m-0 zoom filter"
                    style="
                      width: 40px;
                      border: 2px solid gray;
                      padding: 10px;
                      border-radius: 10px;
                    "
                    :src="itemGroup.icon"
                    alt=""
                  />
                </div>

                <div
                  v-else-if="itemGroup.id != 19 && itemGroup.id != 21"
                  class="text-center m-2"
                  @click="
                    $store.commit('groupRefresh', itemGroup.id);
                    $store.commit('modalShowRefresh', false);
                    $router.push(itemGroup.path);
                  "
                >
                  <img
                    class="text-center p-1 m-0 zoom filter"
                    style="
                      width: 40px;
                      border: 2px solid gray;
                      padding: 10px;
                      border-radius: 10px;
                    "
                    :src="itemGroup.icon"
                    alt=""
                  />
                  <b-badge
                    v-if="
                      [7, 9, 14].includes(itemGroup.id) &&
                      getPendingCount(itemGroup.id) > 0
                    "
                    class="position-absolute"
                    >{{ getPendingCount(itemGroup.id) }}</b-badge
                  >
                </div>
                <p class="text-center h6">{{ itemGroup.group }}</p>
              </div>
            </template>

            <!-- <modalMapaConductual_component /> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { mapGetters, mapMutations } from "vuex";

import modalMapaConductual_component from "./modalMapaConductual_component.vue";

import dataGroup from "../../components/partials/backend/HeaderStyle/groups.json";

export default {
  components: {
    modalMapaConductual_component,
  },
  computed: {
    // ...mapGetters([
    //   "getPendientesPlanes",
    //   "getPendientesOnboardings",
    //   "getPendientesMiOnboarding",
    // ]),
    // pendientesPlanes() {
    //   return this.getPendientesPlanes;
    // },
    // pendientesOnboardings() {
    //   return this.getPendientesOnboardings;
    // },
    // pendientesMiOnboarding() {
    //   return this.getPendientesMiOnboarding;
    // },
  },
  data() {
    return {
      modalShowLocal: false,
      dataGroup: dataGroup,
      usuario: {},
      isJefe: null,
    };
  },
  async mounted() {
    try {
      console.log("mounted home");
      // console.log(this.$router.history.current.fullPath);
      this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
      await this.EsJefe(this.usuario.id);

      // filter isJefe
      if (!this.isJefe && this.usuario.rol.nivel < 3) {
        //3 onboarding admin, 8 Plan de mejoramiento, 15 soporte
        // this.dataGroup = this.dataGroup.data.filter((item) => item.id !== 3 && item.id !== 8 && item.id !== 15);
        this.dataGroup = this.dataGroup.data.filter(
          (item) => item.id !== 19 && item.id !== 9 && item.id !== 17
        );
        console.log("-------------------->", this.dataGroup);
      } else {
        this.dataGroup = this.dataGroup.data;
      }

      console.log("datagroup ", this.dataGroup);
      console.log("datagroup ", this.usuario.rol.nivel);

      this.$store.watch(
        (state) => [state.modalShow].join(),
        () => {
          console.log(
            "actualizando modalshowlocal en modalhome",
            this.modalShowLocal
          );
          this.modalShowLocal = this.$store.state.modalShow;
        }
      );
      await this.getData();
    } catch (error) {
      console.log("error", error);
    }
  },
  methods: {
    // ...mapMutations([
    //   "setPendientesPlanes",
    //   "setPendientesOnboardings",
    //   "setPendientesMiOnboarding",
    //   "setIsOnboarding",
    // ]),
    // updateIsOnboarding(newValue) {
    //   this.setIsOnboarding(newValue);
    // },
    // updatePendientesPlanes(newValue) {
    //   this.setPendientesPlanes(newValue);
    // },
    // updatePendientesOnboardings(newValue) {
    //   this.setPendientesOnboardings(newValue);
    // },
    // updatePendientesMiOnboarding(newValue) {
    //   this.setPendientesMiOnboarding(newValue);
    // },
    getPendingCount(id) {
      if (id === 9) {
        return this.pendientesPlanes;
      }
      if (id === 19) {
        return this.pendientesOnboardings;
      }
      if (id === 7) {
        return this.pendientesMiOnboarding;
      }
      return 0;
    },
    async getData() {
      try {
        this.$isLoading(true);

        // planMejoramiento
        let res = await this.$store.dispatch("hl_get", {
          path: `${
            this.usuario.rol.nivel == 4
              ? "PlanYMejoramiento/"
              : "PlanYMejoramiento/ByResponsableId/" + this.usuario.id
          }`,
        });
        console.log("list planMejoramiento...", res);
        if (res.length > 0) {
          const pendientesPlanes = res.filter((plan) => {
            // console.log("--------------------plan", plan);
            return plan.estadoPlan == 0;
          });
          this.updatePendientesPlanes(pendientesPlanes.length);
        } else {
          this.updatePendientesPlanes(0);
        }
        // onboardingAdmin
        let resOn = await this.$store.dispatch("hl_get", {
          path: `${
            this.usuario.rol.nivel == 4
              ? "OnboardingAdmin/"
              : "OnboardingAdmin/ByResponsableId/" + this.usuario.id
          }`,
        });
        console.log("list onboardings...", resOn);
        if (resOn.length > 0) {
          const pendientesOnboardings = resOn.filter((onboarding) => {
            // console.log("--------------------plan", onboarding);
            return onboarding.estado == 0;
          });
          this.updatePendientesOnboardings(pendientesOnboardings.length);
        } else {
          this.updatePendientesOnboardings(0);
        }
        // misOnboarding
        let resMiOn = await this.$store.dispatch("hl_get", {
          path: "OnboardingAdmin/ByPersonaId/" + this.usuario.id,
        });
        console.log("list onboardings...", resMiOn);
        if (resMiOn.length > 0) {
          const data = await this.fetchAllItemsParallel(resMiOn);

          console.log("------------onbordings", data);
          const pendientesMiOnboarding = data.filter((onboardingAct) => {
            console.log("--------------------onbording", onboardingAct);
            if (pendientesMiOnboarding.length == 0)
              this.updateIsOnboarding(true);
            return onboardingAct.actividadProgreso.porcentajeResp < 100;
          });
          this.updatePendientesMiOnboarding(pendientesMiOnboarding.length);
        } else {
          this.updatePendientesMiOnboarding(0);
        }
        this.$isLoading(false);
      } catch (error) {
        this.$isLoading(false);
        console.log("err", error);
      }
    },
    async getActividadById(actividadId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "ActividadOnboarding/GetActividad/" + actividadId,
        });
        console.log("actividadById...", res);
        return res;
      } catch (error) {
        console.log("err", error);
      }
    },
    async fetchAllItemsParallel(data) {
      const promises = [];

      for (let item of data) {
        let misActividades = item.actividadesId.split(",");
        for (let id of misActividades) {
          promises.push(
            this.getActividadById(id).then(async (actividad) => {
              let actividadProgresoResult =
                await this.getActividadByOnboardingAdminIdAndActividadOnboardingId(
                  item.id,
                  actividad.id
                );
              actividadProgresoResult.changeInput = false;
              return {
                ...actividad,
                fechaInicio: item.fechaInicio,
                fechaFin: item.fechaFin,
                descripcionOnboarding: item.descripcion,
                onboardingAdminId: item.id,
                actividadProgreso: actividadProgresoResult,
              };
            })
          );
        }
      }

      const results = await Promise.all(promises);
      return results;
    },
    async getActividadByOnboardingAdminIdAndActividadOnboardingId(
      onboardingAdminId,
      actividadOnboardingId
    ) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path:
            "ActividadProgresoOnboarding/GetActividadByOnboardingIdAndActividadOnboardingId/" +
            onboardingAdminId +
            "/" +
            actividadOnboardingId,
        });
        console.log("ActividadProgreso...", res);
        return res;
      } catch (error) {
        console.log("err", error);
      }
    },
    async EsJefe(personaId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Actividad/EsJefe/" + personaId,
        });
        this.isJefe = res;
        console.log("----------------------> es jefe", res);
      } catch (error) {
        this.isJefe = null;
        console.log("err", error);
      }
    },
  },
};
</script>
<style>
.responsive-xl-md {
  display: block !important;
}
.responsive-sm {
  display: none !important;
}

@media (max-width: 767px) {
  .content-page.c-payment-block {
    padding: 100px 0 0 !important;
  }
  .responsive-xl-md {
    display: none !important;
  }
  .responsive-sm {
    display: block !important;
  }
}
</style>
